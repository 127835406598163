// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-donate-js": () => import("./../../../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-guide-js": () => import("./../../../src/pages/guide.js" /* webpackChunkName: "component---src-pages-guide-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-secret-archive-js": () => import("./../../../src/pages/secret-archive.js" /* webpackChunkName: "component---src-pages-secret-archive-js" */),
  "component---src-pages-vacancy-js": () => import("./../../../src/pages/vacancy.js" /* webpackChunkName: "component---src-pages-vacancy-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/Article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-guide-article-js": () => import("./../../../src/templates/GuideArticle.js" /* webpackChunkName: "component---src-templates-guide-article-js" */)
}

